import { Divider, Typography } from "antd";
import * as React from "react";
import Page from "../components/page";

const { Title, Paragraph } = Typography;
const PrivacyPolicyPage = () => (
  <Page title="朝鮮カラオケ大会 | プライバシーポリシー">
    <Title>プライバシーポリシー</Title>
    <Divider />
    <Title level={2}>個人情報ならびに個人データの利用目的</Title>
    <Paragraph>
      当ウェブサイトでは、お問い合わせの際に、名前
      (ハンドルネーム等)、メールアドレス等の個人情報を送信いただく場合がございます。
      これらの個人情報は質問に対する回答や必要な情報を連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
    </Paragraph>
    <Paragraph>
      予約システムにおいて、サードパーティのプラットフォーム (Facebook, Twitter,
      Google) 経由でのログインを行う場合、
      認証に必要なアクセストークンに加え、ユーザーのプロフィールとしてハンドルネームをログインに使用したプラットフォームから収集します。
      収集した情報はアプリケーション内でユーザーを識別して表示するための情報に使用します。
    </Paragraph>
    <Divider />
    <Title level={2}>個人情報の第三者への開示</Title>
    <Paragraph>
      当ウェブサイトでは、法令に基づく開示請求があった場合、不正アクセス、脅迫等の違法行為があった場合その他特別の理由のある場合を除き、収集した情報を利用目的以外の用途にに自ら利用し、又は第三者に提供いたしません。
    </Paragraph>
    <Divider />
    <Title level={2}>個人情報の削除</Title>
    <Paragraph>
      ユーザーは当ウェブサイトならびに関連するアプリケーション (予約システム)
      において、個人情報の削除を support (at) dprk-karaoke.club
      に要求することができます。 (at はアットマークに変更して送信してください。)
      個人情報の削除を要求された場合、管理、法律、セキュリティ目的のために保持する義務があるデータを除き、合理的な範囲ですみやかに対応します。
    </Paragraph>
    <Divider />
    <Title level={2}>アクセス解析ツールについて</Title>
    <Paragraph>
      当ウェブサイトでは、サイトの利用状況を把握するために Google
      アナリティクスを利用しています。 Google
      アナリティクスはトラフィックデータの収集のために Cookie を使用しています。
      本規約に関して、詳しくは Google アナリティクス利用規約 をご確認ください。
    </Paragraph>
  </Page>
);
export default PrivacyPolicyPage;
